<template>
  <div class="notfound">
    <div class="content">
      <img :src="require('@/assets/404.png')" alt="">
      <div class="desc">啊哦~ 页面好像走丢了</div>
      <div class="sub-desc">
        <el-button type="text" @click="goBack">{{ countdown }}秒后，返回首页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeRouteLeave(to, from, next) {
    clearInterval(this.inter)
    next()
  },
  data() {
    return {
      inter: null,
      countdown: 5
    }
  },
  mounted() {
    this.inter = setInterval(() => {
      this.countdown--
      if (this.countdown == 0) {
        clearInterval(this.inter)
        this.goBack()
      }
    }, 1000)
  },
  methods: {
    goBack() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.notfound {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    text-align: center;
    img {
      width: 400px;
    }
    .desc {
      font-size: 14px;
      color: #757575;
      line-height: 14px;
    }
  }
}
</style>
